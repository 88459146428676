<script setup lang="ts">
import { ProductDetailsForBackOffice } from "@/api/scion";
import { useT3Store } from "@/store";
import { TwoButton, TwoCheckbox, TwoSearchField } from "@wegift/two-components";
import { computed, onMounted, ref } from "vue";
import { STEP_FORM_02 } from "../../constants";

let productsByCountries = ref<ProductDetailsForBackOffice[]>([]);
let selectedProducts = ref<ProductDetailsForBackOffice[]>([]);
let searchQuery = ref<string>("");

const store = useT3Store();

const emit = defineEmits<{
  (e: "handleNextStep", currentStep: string): void;
}>();

const filterProductsByCountryCodes = (
  products: ProductDetailsForBackOffice[],
  countryCodes: string[]
) => {
  return products.filter((product) => {
    return product.countries.some((country) => {
      return countryCodes.includes(country.code);
    });
  });
};
onMounted(async () => {
  const { availableProductsForCustomer, selectedCountryCodes } = store;
  const productsBySelectedCountries = filterProductsByCountryCodes(
    availableProductsForCustomer,
    selectedCountryCodes
  );
  productsByCountries.value = productsBySelectedCountries;
  handleSelectAll();
});

const handleSelectAll = () => {
  selectedProducts.value = [...productsByCountries.value];
};

const allSelected = computed(() => {
  return selectedProducts.value.length === productsByCountries.value.length;
});

const filteredProducts = computed(() => {
  if (!searchQuery.value) {
    return productsByCountries.value;
  }
  return productsByCountries.value.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

const handleRemoveAll = () => {
  if (selectedProducts.value.length < filteredProducts.value.length) {
    handleSelectAll();
  } else {
    selectedProducts.value = [];
  }
};

const selectProduct = (product: ProductDetailsForBackOffice) => {
  const alreadySelectedIndex = selectedProducts.value.findIndex(
    (selectedProduct) => selectedProduct.code === product.code
  );

  if (alreadySelectedIndex !== -1) {
    selectedProducts.value.splice(alreadySelectedIndex, 1); // Remove the existing product
  } else {
    if (!selectedProducts.value.includes(product)) {
      selectedProducts.value = [...selectedProducts.value, product]; // Add the new product
    }
  }
};

const isSelected = (product: ProductDetailsForBackOffice) => {
  return selectedProducts.value.some(
    (selectedProduct) => selectedProduct.code === product.code
  );
};

const handleNext = () => {
  emit("handleNextStep", STEP_FORM_02);
};

const setProducts = () => {
  store.setSelectedProducts(selectedProducts.value);
};

const submitProducts = async () => {
  setProducts();
  handleNext();
};
</script>

<template>
  <h1 class="mb-6 flex text-xl" data-testid="form-title">
    Please select which merchants you would like access to
  </h1>
  <div class="flex items-center justify-between">
    <div class="flex items-center gap-4">
      <h2
        class="font-semiblack flex items-center font-heading text-xl leading-7"
        data-testid="selected-products-heading"
      >
        Selected merchants ({{ selectedProducts.length }})
      </h2>
      <TwoButton
        data-testid="bulk-product-select"
        :class="{
          'btn-primary w-28': allSelected,
          'btn-secondary w-28': !allSelected,
        }"
        @click="handleRemoveAll"
      >
        {{ allSelected ? "Remove All" : "+ Add all" }}
      </TwoButton>
    </div>
    <div class="flex items-center">
      <TwoSearchField
        v-model="searchQuery"
        placeholder="Search"
        data-testid="products-search-input-field"
      />
    </div>
  </div>

  <hr class="border-gray-100 my-6" />
  <div
    class="list-product h-[400px] w-full overflow-y-scroll"
    data-testid="select-products-container"
  >
    <div class="grid grid-cols-5 gap-4">
      <div
        v-for="(product, index) in filteredProducts"
        :data-testid="`select-product-item-${product.code}`"
        :key="index"
        class="flex h-[159px] cursor-pointer flex-col items-center"
        @click="selectProduct(product)"
      >
        <div
          class="relative mb-1 max-h-[98px] max-w-[114px] rounded-lg px-4 py-5"
          :class="{
            'border-[2px] border-black shadow-productCell': isSelected(product),
            'hover: max-h-[96px] border-[1px] border-grey-100 hover:max-w-[112px] hover:border-[2px] hover:border-grey-200 hover:shadow-productCell':
              !isSelected(product),
          }"
        >
          <TwoCheckbox
            :data-testid="`select-product-checkbox-${product.code}`"
            v-if="isSelected(product)"
            class="checkbox checkboxProductCell absolute left-3 top-3 z-10 h-4 w-4"
            :modelValue="isSelected(product)"
          />

          <img
            :src="product.image_url"
            :alt="product.name"
            class="z-0 h-auto max-h-[5rem] w-full max-w-[5rem] object-cover"
            :class="{ 'opacity-50': isSelected(product) }"
          />
        </div>

        <p
          :data-testid="`select-product-name-${product.code}`"
          class="mt-2 text-center"
        >
          {{ product.name }}
        </p>
      </div>
    </div>
  </div>
  <TwoButton
    data-testid="apply-button"
    class="btn-primary mt-4 w-64"
    :disabled="selectedProducts.length === 0"
    @click="submitProducts"
  >
    Apply for {{ selectedProducts.length }} merchants
  </TwoButton>
</template>
